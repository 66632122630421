<template>
    <div class="holder">
        <div class="mt-3 mb-3">
            <span style="cursor: pointer;" @click="goBack()" class="mr-2"><i class="fa fa-arrow-left"></i></span>
            <span style="cursor: pointer;" @click="goBack()">Back</span>
        </div>


        <div v-if="loadingProperty" class="m-auto text-center mt-4">
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
        <!-- <div class="empty text-center mt-4 " v-if="propDetails == null && !loadingProperty">No Record</div> -->
        <div  v-else>
            <div class="image-gallerey-view">
                <div class="row">
                    <div class="col-md-6" v-if="propDetails.images">
                        <div class="main-img">
                            <img v-if="propDetails.images.length == 0" class="w-100 image-main" src="../assets/images/property-img.jpg" alt="image">
                            <img v-else class="w-100 image-main" :src="propDetails.images[0]" alt="main-image">
                            <section data-toggle="modal" data-target="#image-update">
                                <img src="../assets/icons/edit.svg" alt="dit">
                            </section>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row" v-if="propDetails.images">
                            <div class="col-md-6 mb-2" v-for="(item, index) in propDetails.images.slice(1, 5)" :key="index">
                                <!-- :class="[index == 4 ? 'last-img' : '']" -->
                                <div class="small-img" :class="{ 'last-img': index === 3 }">
                                    <h6 class="number-text" v-if="index == 3">+ {{ propDetails.images.length }}</h6>
                                    <img class="w-100" :src="item" alt="others">
                                    <!-- <img class="w-100" src="../assets/placeholders-images/small-img2.svg" alt="others"> -->
                                </div>
                            </div>
                            <div class="col-md-6 mb-2" v-if="propDetails.images == null">
                                <div class="small-img">
                                    <img class="w-100" src="../assets/placeholders-images/small-img2.svg" alt="main-image">
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            
            </div>

            <div class="prop-detail-holder mt-3">
                <div class="title-section">
                    <div class="text-with-edit">
                        <h4 class="title"> {{propDetails.title}}</h4>
                        <span data-toggle="modal" data-target="#basic-info">Edit</span>
                    </div>
                    <p class="address-text" v-if="propDetails">{{propDetails.address}}</p>
                    <h6><img src="../assets/icons/location.svg" alt="location"> {{propDetails.city}}, {{propDetails.state}}</h6>
                </div>
                
                <div>
                    <div class="text-with-edit mt-5">
                        <h4 class="header-text-top">Property specifications</h4>
                        <span data-toggle="modal" data-target="#prop-specification">Edit</span>
                    </div>
                    <div class="prop-price-spec">
                        <div>
                            <p>Price</p>
                            <h6>₦{{ Number(propDetails.price).toLocaleString() }}</h6>
                        </div>
                        <div>
                            <p>Bedrooms</p>
                            <h6>{{ propDetails.bedrooms }}</h6>
                        </div>
                        <div>
                            <p>Bathrooms</p>
                            <h6>{{ propDetails.bathrooms }}</h6>
                        </div>
                        <div>
                            <p>Toilets</p>
                            <h6>{{ propDetails.toilets }}</h6>
                        </div>
                        <div>
                            <p>Property type</p>
                            <h6>{{propDetails.businesstype == 1 ? 'For Rent' : 'For Sale'}}</h6>
                        </div>
                        <div>
                            <p>Square feet</p>
                            <h6>{{propDetails.squarefootage}}</h6>
                        </div>
                    </div>
                </div>
            </div>

            <div class="description-holder" v-if="propDetails.description">
                <h4 class="header-text-top">Description</h4>
                
                <h6>{{propDetails.description}}</h6>
                <!-- <p>See More...</p> -->
            </div>

            <div class="offerings-holder mt-4">
                <div class="text-with-edit">
                    <h4 class="header-text-top">Offerings available here</h4>
                    <span data-toggle="modal" data-target="#prop-features">Edit</span>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="">
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-4 offering-list" v-for="facility in facilities" :key="facility">
                                    <p>{{facility}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="adddress-holder mt-4" v-if="propDetails.address">
                <img src="../assets/icons/location.svg" alt="location">
                <div class="prop-address">
                    <h5>Property address</h5>
                    <h6 v-if="propDetails">{{propDetails.address}}</h6>
                </div>
            </div>

            <div class="description-holder d-none">
                <h4 class="header-text-top">Terms & conditions</h4>
                <h6>Lorem ipsum dolor sit amet consectetur. Tortor laoreet volutpat massa felis leo tortor. Dolor non nulla 
                    laoreet vel nullam scelerisque nulla aliquam sed.
                    Volutpat ultrices porta sit velit nisl vitae posuere nunc dictumst. In adipiscing congue id sit.
                    Lorem ipsum dolor sit amet consectetur. Tortor laoreet volutpat massa felis leo tortor. Dolor non nulla laoreet 
                    vel nullam scelerisque nulla aliquam sed. Volutpat ultrices porta sit velit nisl vitae posuere nunc dictumst.
                    In adipiscing congue id sit Dolor non nulla laoreet vel nullam scelerisque nulla aliquam sed. Volutpat ultrices
                    porta sit velit nisl vitae posuere nunc dictumst. In adipiscing congue id sit
                </h6>
                <p data-toggle="modal" data-target="#termscondition">See More...</p>
            </div>

            <div class="text-center mt-5 d-none">
                <button
                    class="btn btn-primary share-btn"
                    @click="socialShare(propDetails)"
                >
                    <img
                    class="share-btn-img"
                    src="../assets/images/share.svg"
                    alt="share"
                    />
                </button>
            </div>

            <div class="modal fade" id="share-modal">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                    <div class="modal-body text-center">
                        <p>Please select a network below to share this property.</p>
                        <div class="d-flex flex-column">
                        <ShareNetwork
                            class="mb-3"
                            network="email"
                            :url="sharing.url"
                            :title="sharing.title"
                            :quote="sharing.quote"
                            :hashtags="sharing.hashtags"
                        >
                            <button class="btn btn-outline-dark">
                            <i class="far fa-envelope mr-2"></i>
                            <span>Share on Email</span>
                            </button>
                        </ShareNetwork>
                        <ShareNetwork
                            class="mb-3"
                            network="whatsapp"
                            :url="sharing.url"
                            :title="sharing.title"
                            :quote="sharing.quote"
                            :hashtags="sharing.hashtags"
                        >
                            <button class="btn btn-outline-dark">
                            <i class="fab fa-whatsapp mr-2"></i>
                            <span>Share on WhatsApp</span>
                            </button>
                        </ShareNetwork>
                        <ShareNetwork
                            class="mb-3"
                            network="twitter"
                            :url="sharing.url"
                            :title="sharing.title"
                            :quote="sharing.quote"
                            :hashtags="sharing.hashtags"
                        >
                            <button class=" btn btn-outline-dark">
                            <i class="fab fa-twitter mr-2"></i>

                            <span>Share on Twitter</span>
                            </button>
                        </ShareNetwork>
                        <ShareNetwork
                            class="mb-3"
                            network="facebook"
                            :url="sharing.url"
                            :title="sharing.title"
                            :quote="sharing.quote"
                            :hashtags="sharing.hashtags"
                        >
                            <button class="btn btn-outline-dark">
                            <i class="fab fa-facebook-f mr-2"></i>
                            <span>Share on Facebook</span>
                            </button>
                        </ShareNetwork>
                        <ShareNetwork
                            class="mb-3"
                            network="linkedin"
                            :url="sharing.url"
                            :title="sharing.title"
                            :quote="sharing.quote"
                            :hashtags="sharing.hashtags"
                        >
                            <button class="btn btn-outline-dark">
                            <i class="fab fa-linkedin-in mr-2"></i>
                            <span>Share on LinkedIn</span>
                            </button>
                        </ShareNetwork>
                        </div>

                        <div>
                        <button
                            type="button"
                            class="btn btn-secondary float-right"
                            data-dismiss="modal"
                        >
                            Close
                        </button>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- terms and condtion modal starts -->
        <div class="modal fade" tabindex="-1" id="termscondition">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title"></h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p class="modal-top-text">Terms & Condtions</p>

                        <h6 class="modal-content-body">
                            Lorem ipsum dolor sit amet consectetur. Tortor laoreet volutpat massa felis leo tortor. Dolor non nulla laoreet vel nullam scelerisque nulla aliquam sed. Volutpat ultrices porta sit velit nisl vitae posuere nunc dictumst. In adipiscing congue id sit.
                            Lorem ipsum dolor sit amet consectetur. Tortor laoreet volutpat massa felis leo tortor. Dolor non nulla laoreet vel nullam scelerisque nulla aliquam sed. Volutpat ultrices porta sit velit nisl vitae posuere nunc dictumst. In adipiscing congue id sit 
                            Dolor non nulla laoreet vel nullam scelerisque nulla aliquam sed. Volutpat ultrices porta sit velit nisl vitae posuere nunc dictumst. In adipiscing congue id sit
                            Lorem ipsum dolor sit amet consectetur. Tortor laoreet volutpat massa felis leo tortor. Dolor non nulla laoreet vel nullam scelerisque nulla aliquam sed. Volutpat ultrices porta sit velit nisl vitae posuere nunc dictumst. In adipiscing congue id sit 
                            Dolor non nulla laoreet vel nullam scelerisque nulla aliquam sed. Volutpat ultrices porta sit velit nisl vitae posuere nunc dictumst. In adipiscing congue id sit
                        </h6>
                    </div>
               
                </div>
            </div>
        </div>
        <!-- terms and condtion modal ends -->

        <!-- Modal for updating basic info starts -->
        <div class="modal fade" id="basic-info" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Update basic info</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                   <form>
                        
                        <div class="form-group">
                            <label for="title">Title of post</label>
                            <input v-model="title" type="text" class="form-control" id="title" placeholder="Title of the property post">
                        </div>
                        <div class="form-group">
                            <label for="description">Description</label>
                            <!-- <input type="text" class="form-control" id="description" placeholder="Describe the property"> -->
                            <textarea class="form-control" name="description" id="description" v-model="description" placeholder="Describe the property"></textarea>
                        </div>
                        <!-- <div class="form-group">
                            <label for="price">Price</label>
                            <input class="form-control" name="price" id="description" v-model="amount" placeholder="Property Price">
                        </div> -->
                        <!-- <div class="form-group">
                           <label for="type">What type of property is it?</label>
                            <select class="form-control" id="type">
                                <option>Residential</option>
                                <option>Commercial</option>
                            </select>
                        </div> -->

                        
                        <div class="form-group">
                            <label for="state">Which state is the property in?</label>
                            <select class="form-control" id="state" v-model="state">
                                <option 
                                v-for="state in states"
                                :key="state.id"
                                :value="state.id"
                                >{{state.name}}</option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label for="city">Which city?</label>
                            <select class="form-control" id="city" v-model="city">
                                <option 
                                v-for="city in cities"
                                :key="city.id"
                                :value="city.id"
                                >{{city.name}}</option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label for="address">Street, building (Address)</label>
                            <input type="text" class="form-control" id="address" placeholder="Enter property address">
                        </div>
                   </form>
                </div>
                <div class="modal-footer update-buttons">
                    <button type="button" class="btn cancel  btn-block" data-dismiss="modal">CANCEL</button>
                    <button type="button" class="btn update btn-block" @click="submitForm()">
                        <span v-if="!propertyUpdating"> UPDATE</span>
                        <span class="submitting-text" v-if="propertyUpdating">Updating....</span>
                    </button>
                </div>
                </div>
            </div>
        </div>
        <!-- Modal for updating basic info ends -->


        <!-- Modal for updating property specifications starts -->
        <div class="modal fade" id="prop-specification" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Update specifications</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                   <form>
                        <div class="mt-2 mb-4">
                            <div class="form-check" style="margin-bottom: 15px;">
                                <p class="radio-label">What is the property availability?</p>
                                <input class="form-check-input" type="radio" name="propAvailability" id="rent" value="1" v-model="availability">
                                <label class="form-check-label" for="rent" style="padding-top: 2px;">
                                For Rent
                                </label>
                            </div>
                            <div class="form-check" style="margin-bottom: 15px;">
                                <input class="form-check-input" type="radio" name="propAvailability" id="sale"  value="3" v-model="availability">
                                <label class="form-check-label" for="sale" style="padding-top: 5px;">
                                For Sale
                                </label>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="price">Price</label>
                            <input class="form-control" name="price" id="description" v-model="theRentValueAmount" placeholder="Property Price">
                        </div>
                        <div class="form-group">
                            <label for="bedrooms">Bedroom(s)</label>
                            <input v-model="noOfBedroom" type="number" class="form-control" id="bedrooms" placeholder="No of bedrooms">
                        </div>
                        <div class="form-group">
                            <label for="bathroms">Bathroom(s)</label>
                            <input v-model="noOfBathroom" type="number" class="form-control" id="bathrooms" placeholder="No of bathrooms">
                        </div>

                        <div class="form-group">
                            <label for="toilets">Toilet(s)</label>
                            <input v-model="noOfToilets" type="number" class="form-control" id="toilet" placeholder="No of toilets">
                        </div>

                        <div class="form-group">
                            <label for="squarefeet">How many square feet?</label>
                            <input v-model="squareFootage" type="number" class="form-control" id="squarefeet" placeholder="No of square feet">
                        </div>
                        
                        <div class="form-group">
                           <label for="type">What type of property is it?
                                <span class="ml-3" v-if="loadingPropertyType">
                                    <i class="fa fa-spinner fa-spin"></i>
                                </span>
                           </label>
                            <select class="form-control" id="type" @change="getPropertySubTypes(type)" v-model="type">
                                <option
                                    v-for="propertyType in propertyTypes.slice(0, 2)"
                                    :key="propertyType.id"
                                    :value="propertyType.id"
                                    >{{ propertyType.type }}
                                </option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label for="subtype">What is the property subtype? 
                                <span class="ml-3" v-if="loadingPropertySubType">
                                    <i class="fa fa-spinner fa-spin"></i>
                                </span>
                            </label>
                            <select class="form-control" id="subtype" v-model="subtype">
                                <option
                                    v-for="subType in propertySubTypes"
                                    :key="subType.id"
                                    :value="subType.id"
                                    >{{ subType.subtype }}
                                </option>
                                    
                            </select>
                        </div>
                        
                   </form>
                </div>
                <div class="modal-footer update-buttons">
                    <button type="button" class="btn cancel  btn-block" data-dismiss="modal">CANCEL</button>
                    <button type="button" class="btn update btn-block" @click="submitForm()">
                        <span v-if="!propertyUpdating"> UPDATE</span>
                        <span class="submitting-text" v-if="propertyUpdating">Updating....</span>
                    </button>
                </div>
                </div>
            </div>
        </div>
         <!-- Modal for updating property specifications ends -->

        <!-- Modal for updating property specifications starts -->
        <div class="modal fade" id="prop-features" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Update facilities</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <form>

                            <div class="features-amenities-holder">
                                <p class="radio-label">Select the key features and amenities </p>
                                <div class="" >
                                    <button type="button" @click="selectFeatures(item)" class="features-btn btn" :class="{ 'selectedFeature': isSelected(item) }" v-for="(item, index) in facilitiesAmenities"
                                    :key="index">{{ item }}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer update-buttons">
                        <button type="button" class="btn cancel  btn-block" data-dismiss="modal">CANCEL</button>
                        <button type="button" class="btn update btn-block" @click="submitForm()">
                            <span v-if="!propertyUpdating"> UPDATE</span>
                            <span class="submitting-text" v-if="propertyUpdating">Updating....</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal for updating property specifications ends -->

        <!-- Modal for updating property images starts -->
        <div class="modal fade" id="image-update" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Update image gallery</h5>
                        <button type="button" class="close" @click="selectPropertyImage" >
                          <span class="plus" aria-hidden="true">+</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="overlay" v-if="deletingImage">   
                        </div>
                        <form style="position: relative;">
                            <div class="m-auto text-center mt-4 loadingDiv" v-if="deletingImage">
                                <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                            </div>
                            <div class="">
                               <div class="">
                                    <div class="row">
                                        <div class="col-12 col-md-4 col-sm-6 mb-3 view-image-holder" v-for="(item, index) in propDetails.images" :key="index">
                                            <img class="update-image" :src="item" alt="">
                                            <span v-if="propDetails.images.length > 1" class="delete-icon" @click="deletePropertyImage(item)"><i class="fa fa-trash"></i></span>
                                        </div>
                                    </div>
                               </div>
                            </div>
                            <p style="font-size: 11px;" class="uploading-doc mt-4" v-if="uploadingPhoto">Uploading photo....</p>
                            <p style="font-size: 11px;">Delete or add property image to image gallery, click the plus icon to add new image.</p>
                            <input type="file" ref="propertyImage" @change="handleFileUploadImages($event)" class="d-none" >
                            <div class="mt-4">
                                <div class="row justify-content-center">
                                    <div class="" v-for="(item, index) in uploadedPhotos"
                                    :key="index">
                                    <div class="file-display">
                                        <h6>{{item}}<span class="" @click.stop="removeUploadedImages(index)">X</span></h6>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer update-buttons">
                        <button type="button" class="btn cancel  btn-block" data-dismiss="modal">CANCEL</button>
                        <button type="button" class="btn update btn-block" @click="submitForm()" :disabled="uploadingPhoto || propertyUpdating || !this.propertyImageFile">
                            <span v-if="!propertyUpdating && !uploadingPhoto && !deletingImage">UPDATE</span>
                            <span class="submitting-text" v-if="propertyUpdating && !uploadingPhoto && !deletingImage">Updating....</span>
                            <span class="submitting-text" v-if="uploadingPhoto">Uploading image....</span>
                            <span class="submitting-text" v-if="deletingImage">Deleting image....</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal for updating property images ends -->
    </div>
</template>


<script>
    import properties from '../services/properties';
    import $ from "jquery";
    import "@fortawesome/fontawesome-free/css/all.css";
    // import Loader from '../components/Loader.vue';
    export default {
        name: 'PropertyDetails',
        component: {
            // Loader
        },
        data() {
            return {
                propertyUpdating: false,
                loadingProperty: false,
                uploadingPhoto: false,
                deletingImage: false,
                subscription: "",
                loadingPropertyType: false,
                loadingPropertySubType: false,
                loadingLocation: false,
                loadingCity: false,
                propDetails: [],
                oppid: "",
                facilities: [],
                userCode: [],
                sharing: {
                    url: "",
                    title: "",
                    description: "",
                    quote: "",
                    hashtags: ""
                },
                networks: [
                    {
                    network: "email",
                    name: "Email",
                    icon: "far fah fa-lg fa-envelope",
                    color: "#333333"
                    }
                ],
                facilitiesAmenities: [],
                businesstypenumber: "",
                propertyTypes: [],
                propertySubTypes: [],
                states: [],
                cities: [],
                city: "",
                state: "",
                title: "",
                description: "",
                address: "",
                type: "",
                subtype: "",
                amount: "",
                amount2: "",
                availability: "",
                selectedFeaturesNames: [],
                noOfBedroom: "",
                noOfBathroom: "",
                noOfToilets: "",
                squareFootage: "",
                propertyId: "",
                propertyImageFile: "",
                propertyFirstImage: "",
                uploadedPhotos: [],
                
            }
        },
        watch: {
            state(newValue) {
            if (newValue) {
                this.getCities();
            }
            // Add your logic here
            },
        },
        computed: {
            theRentValueAmount: {
                get() {
                    return this.amount;
                },
                set(value) {
                    let amountt = value.split(",").join("");
                    this.amount2 = (amountt);
                    let decimalArray = amountt.split(".");
                    if (decimalArray.length === 2) {
                    amountt = decimalArray[0];
                    amountt = (+amountt).toLocaleString();
                    this.amount = amountt + "." + decimalArray[1];
                    } else {
                    this.amount = (+amountt).toLocaleString();
                    }
                },
            },
        },
        created() {
            this.subscription = this.$store.getters.getSubscription;
        },
        methods: {
            goBack() {
                // this.$router.go(-1);
                this.$router.push({path: `/user/smart-agent`});
            },
            selectFeatures(name) {
                const index = this.selectedFeaturesNames.indexOf(name);
                if (index === -1) {
                    this.selectedFeaturesNames.push(name);
                } else {
                    this.selectedFeaturesNames.splice(index, 1);
                }

            },
            isSelected(name) {
                return this.selectedFeaturesNames.includes(name);
            },
            async propertyDetails() {
                this.loadingProperty = true;
                this.oppid = this.$route.params.id;
               await properties.fetchAgentPropertiesById(this.oppid).then(data => {
                    // console.log("Property:", data);
                    this.loadingProperty = false;
                    if (data.success) {
                        this.propertyId = data.property.id;
                        this.propDetails = data.property;
                        this.facilities = JSON.parse(data.property.facilities);
                        this.title = data.property.title;
                        this.description =  data.property.description;
                        this.theRentValueAmount = data.property.price;
                        this.availability = data.property.businesstype;
                        this.type = data.property.type;
                        this.subtype = data.property.subtype;
                        this.state = data.property.stateid;
                        this.stateId = data.property.stateid;
                        this.noOfBedroom = data.property.bedrooms;
                        this.noOfBathroom = data.property.bathrooms;
                        this.noOfToilets = data.property.toilets;
                        this.squareFootage = data.property.squarefootage
                        this.selectedFeaturesNames = JSON.parse(data.property.facilities);
                       
                        this.city = data.property.cityid;
                        this.getCities();
                        this.address = data.property.address;
                        this.getPropertySubTypes(this.type);
                        // var x = document.getElementById("myImg").complete;
                        // document.getElementById("demo").innerHTML = x;
                        // this.$refs.myModal.classList.remove("open");
                    } else {
                        this.$toast.error(data.error);
                    }
                }).catch((err) => {
                    this.loadingProperty = false;
                    console.log(err);
                    // this.$toast.error("please check your network and refresh the page");
                    
                });
            },
            facilitiesAndAmenities() {
                properties.fetchFacilitiess().then((res) => {
                    this.facilitiesAmenities =  res.facilities;
                }).catch((err) => {
                    console.log(err);
                });
            }, 

            getPropertyTypes() {
                this.loadingPropertyType = true;
                //fetch properties
                properties
                    .fetchPropertyType()
                    .then((data) => {
                    this.propertyTypes = data.propertytypes;
                    this.loadingPropertyType = false;
                    })
                    .catch((err) => {
                    console.log(err);
                });
            },
            getBusinessTypes() {
                //fetch properties
                properties
                    .fetchBusinessTypes()
                    .then((data) => {
                    if (data.success === "success") {
                        this.businesstypenumber = data.businesstype;
                    }
                    })
                    .catch((err) => {
                    console.log(err);
                });
            },
            getPropertySubTypes(id) {
                this.loadingPropertySubType = true;
                properties
                    .fetchPropertySubType(id)
                    .then((data) => {
                    
                    this.propertySubTypes = data.propertysubtypes;
                    this.loadingPropertySubType = false;
                    })
                    .catch((err) => {
                    console.log(err);
                });
            },
            getStates() {
            this.loadingLocation = true;
            properties
                .fetchStates()
                .then((data) => {
                this.loadingLocation = false;
                this.states = data.states;
                // this.getCities();
                })
                .catch((err) => {
                console.log(err);
                });
            },
            // selectState(value) {
                
            // },
            getCities() {
                this.loadingCity = true;
                properties
                    .fetchCities(this.state)
                    .then((data) => {
                        this.loadingCity = false;
                        this.cities = data.cities;
                    })
                    .catch((err) => {
                    console.log(err);
                });
            },
            selectPropertyImage() {
                this.$refs.propertyImage.click();
            },
            removeUploadedImages(index) {
                this.uploadedPhotos.splice(index, 1);
            },
            handleFileUploadImages(event) {
                this.propertyImageFile = event.target.files[0];
                const file = event.target.files[0];
                // Handle the uploaded file here
                this.propertyImageFile = event.target.files[0];
                // const file = event.target.files[0];
                this.uploadingPhoto = true;
                if (this.uploadedPhotos.length == 0) {
                    this.propertyFirstImage = URL.createObjectURL(file);
                }
                

                if (!this.propertyImageFile) {
                    this.uploadingPhoto = false;
                    // this.$toast.success("success");
                    return this.$toast.error("select a file");
                }
                if (
                    // this.evidenceFile.type != "application/pdf"
                    this.propertyImageFile.type === "image/jpeg" ||
                    this.propertyImageFile.type === "image/jpg" ||
                    this.propertyImageFile.type === "image/png" ||
                    this.propertyImageFile.type === "image/gif" ||
                    // this.propertyImageFile.type === "application/pdf" ||
                    this.propertyImageFile.type === "svg+xml"
                ) {
                    this.uploadingPhoto = true;
                } else {
                    this.uploadingPhoto = false;
                    this.propertyImageFile = "";
                    return this.$toast.error("select a IMAGE file");
                }
                
                // Handle the uploaded file here
                const sizeInMb = this.propertyImageFile.size / (1024 * 1024).toFixed(3);
                if (sizeInMb > 5) {
                    this.uploadingPhoto = false;
                    return this.$toast.error("File size more than 5MB");
                }
                let formData = new FormData();
                formData.append("file", this.propertyImageFile);
                formData.append("documentType", this.propertyImageFile.name);
                properties.uploadPropertyimages(formData)
                .then((res) => {
                    this.uploadingPhoto = false;
                    if (res.success) {
                        JSON.stringify(this.uploadedPhotos.push(res.filename));
                        window.localStorage.setItem("__uploadedImages", JSON.stringify(this.uploadedPhotos))
                        this.$toast.success("SUCCESS");
                    } else {
                       this.$toast.error("ERROR");
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.uploadingPhoto = false;
                });

            },

            deletePropertyImage(url) {
                this.deletingImage = true;
                let data = {
                    id: this.propertyId,
                    url: url,
                }
                data = JSON.stringify(data);
                properties.agentDeleteSinglePropertyImage(data).then((res) => {
                    // console.log("deleted image", res);
                    this.deletingImage = false;
                    if (res.success) {
                        this.propertyDetails()
                        this.$toast.success("Image removed");
                    }
                }).catch((err) => {
                    this.deletingImage = false;
                  this.$toast.error("Error occurred");
                  console.log(err);
                });
            },
            submitForm() {
                this.propertyUpdating = true;
                if (this.subscription != "YES") {
                    this.propertyUpdating = false;
                    return this.$toast.error("Subscribe before editing property");
                }
                if (this.subscription == "YES") {
                    const body = {
                    title: this.title,
                    // lastname: this.lastName,
                    // firstname: this.firstName,
                    // gender: this.gender,
                    type: this.type,
                    subtype: this.subtype,
                    bedrooms: this.noOfBedroom,
                    bathrooms: this.noOfBathroom,
                    toilets: this.noOfToilets,
                    squarefootage: this.squareFootage,
                    stateid: this.state,
                    cityid: this.city,
                    price: Number(this.amount2),
                    businesstype: Number(this.availability),
                    // evidenceofownership: this.evidenceServerFileName,
                    facilities: this.selectedFeaturesNames,
                    // image_array: this.uploadedPhotos,
                    // status: this.propertyStatus,
                    description: this.description,
                    image_array: this.uploadedPhotos,
                    id: this.propertyId,
                    };
                    properties.agentRegisterProperty(JSON.stringify(body))
                    .then((res) => {
                        this.propertyUpdating = false;
                        if (res.success) {
                            this.propertyDetails()
                            this.$toast.success("PROPERTY UPDATTED SUCCESSFULLY");
                            $("#prop-specification")
                            .removeClass("fade")
                            .modal("hide");
                            $("#basic-info")
                            .removeClass("fade")
                            .modal("hide");
                            $("#prop-features")
                            .removeClass("fade")
                            .modal("hide");
                            $("#image-update")
                            .removeClass("fade")
                            .modal("hide");
                            this.uploadedPhotos = [];
                        } else {
                            this.$toast.error("ERROR");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        this.propertyUpdating = false;
                    });
                } else {
                    this.propertyUpdating = false
                    this.$toast.error("Unable to submit. Please try again");
                }
            },
            socialShare(property) {
                // console.log("property pin,", property)
                // this.sharing.url = `https://smart.myshelta.com/user/opportunity-property/${property.id}`;
                if (property.type === "To Let") {
                    // this.sharing.url = `https://smarttest.myshelta.com/user/opportunity-property/${property.id}`;
                    this.sharing.url = `https://myshelta.com/property/${property.id}`;
                } else {
                    this.sharing.url = `https://sales.myshelta.com/property-details/${property.pin}/${this.userCode}`;
                    // this.sharing.url = `https://sales.myshelta.com/property-details/${property.id}/${this.userCode}`;
                }

                this.sharing.title = ` ${property.title} on Shelta. Click to view details.`;
                this.sharing.description =
                    "We provide a seamless and convenient rental and facility management experience. Too often, renting a home puts property owners and renters in difficult situations regarding their relationship. Shelta offers well-researched, thought out strategies to make an otherwise difficult experience pleasurble for both parties.";

                $("#share-modal").modal("show");
            },
        },
        mounted() {
            this.propertyDetails(); 
            this.userCode = this.$store.getters.getUser.usercode;
            this.facilitiesAndAmenities();
            this.getPropertyTypes();
            this.getBusinessTypes();
            this.getStates();


        },
    }

</script>


<style lang="scss" scoped>
$primary: #0033ea;
$white: #ffffff;
$yellow: #ffb100;
$asheGrey: #555C74;
$offblack: #2B3352;




.main-img {
    position: relative;
    .image-main {
        border-radius: 8px;
        height: 100%;
        max-height: 438px;
        min-height: 400px;
        object-fit: cover;
        // height: 195px;
    }
    section {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
    }
}
.update-image {
    width: 100%;
    height: 115px;
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
}

.small-img {
    // min-height: 215px;
    // height: 100%;
    // width: 100%;
    img {
        border-radius: 8px;
        // max-height: 215px;
        object-fit: cover;
        height: 215px;
        // height: 100%;
        // min-height: 195px;
        // max-height: 215px;
    }
}

.plus {
    border: 1px solid;
    border-radius: 50px;
    padding: 4px 8px;
}
.view-image-holder {
    position: relative;
    span {
        position: absolute;
        right: 22px;
        top: 5px;
        cursor: pointer;
        i {
            color: #eb2020;
        }
    }
}

.overlay {
    position: fixed;
    background: rgba(0, 0, 0, 0.2);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 12;
}

// .last-img {
//     // background-color: rgba(0, 0, 0, 0.5);
//     background-color: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), rgba(0, 0, 0, 0.5);
// }
.last-img {
    position: relative;
}
.last-img::before {
    border-radius: 8px;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
    pointer-events: none; /* Allow interactions with the underlying image */
}.submitting-text {
  // color: #48b881 !important;
  // text-align: center;
  // margin-top: 20%;
  // font-size: 14px !important;
  animation: blink 1s infinite; 
}

.file-display {
  position: relative;
  width: max-content;
  padding: 10px 15px;
  border: 1px solid #CCD6FB;
  margin-top: 15px;
  margin-right: 15px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  span {
    font-size: 10px;
    // bottom: 31px;
    // position: absolute;
    // right: -10%;
    margin-left: 15px;
    border: 1px solid #CCD6FB;
    border-radius: 50px;
    padding: 5px 8px;
    color: #2B3352;
    cursor: pointer;
    outline: 0;
    box-shadow: none;
  }
  h6 {
    color: #2B3352 !important;
    margin-bottom: 0 !important;
    font-size: 12px;
    font-family: Lato;
  }
}

.number-text {
    position: absolute;
    top: 35%;
    left: 35%;
    color: $white;
    font-family: Lato;
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    line-height: 54px;
}

.title-section {
    h4 {
        font-family: Lato;
        font-size: 24px;
        font-weight: 600;
        line-height: 36px;
        text-align: left;
        color: #2B3352;
    }
    span {
            font-family: Lato;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            text-align: right;
            color: #0033EA;
            border-bottom: 1px solid #0033EA;
            cursor: pointer;
        }
    h6 {
        font-family: Lato;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        color: $asheGrey;
    }
}
.address-text {
    font-family: Lato;
    font-size: 14px;
    font-weight: 200;
    line-height: 30px;
    text-align: left;
    color: $asheGrey;
    padding-bottom: 5px;
}
.text-with-edit {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    span {
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: right;
        color: #0033EA;
        border-bottom: 1px solid #0033EA;
        cursor: pointer;
    }
}
.prop-price-spec {
    border: 1px solid #E2E8F0;
}

.header-text-top {
    font-family: Lato;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    text-align: left;
    color: #2B3352;
}

.prop-price-spec {
    display: grid;
    // justify-content: space-between;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
    padding: 20px 40px;
    border-radius: 8px;
    margin-top: 20px;
    overflow: scroll;
}
.prop-price-spec > div {
    border-right: 1px solid #E2E8F0;
    padding-left: 20px;
    padding-right: 20px;
}
.prop-price-spec > div:first-child {
    padding-left: 0;
}
.prop-price-spec > div:last-child {
    border-right: none;
}
.prop-price-spec > div {
    p {
        font-family: Lato;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: $asheGrey;
        margin-bottom: 15px !important;
    }
    h6 {
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        color: #2B3352;
    }
}

.description-holder {
    margin-top: 30px;
    h6 {
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: justify;
        color: $asheGrey;
        margin-bottom: 20px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
    p {
        font-family: Lato;
        font-size: 14px;
        font-weight: 500;
        line-height: 17.5px;
        text-align: left;
        color: #000A2F;
        text-decoration: underline;
        cursor: pointer;
    }
}

.offerings-holder {
    border: 1px solid #E2E8F0;
    padding: 24px;
    border-radius: 8px;
}

.offering-list {
    p {
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
        color: $asheGrey;
        // border: 1px solid #555C74;
        // border-radius: 50px;
        // width: fit-content;
        // padding: 0 15px;
    }
}

.adddress-holder {
    border: 1px solid #E2E8F0;
    padding: 24px;
    border-radius: 8px;
    display: flex;
    gap: 10px;
    align-items: flex-start;
}

.prop-address {
    h5 {
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        margin-bottom: 0;
    }
    h6 {
        font-family: Lato;
        font-size: 14px;
        font-weight: 100;
        line-height: 21px;
        text-align: left;
        margin-bottom: 0;
    }
}
@media screen and (max-width: 599px) {
  .main-img {
    margin-bottom: 10px;
    .image-main {
        max-height: 215px;
        object-fit: cover;
    }
  }
}

.stat-img {
  width: 25px;
}
.share-btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #0033ea;
  border-color: #0033ea;
}
.share-btn-img {
  width: 20px;
}

.modal-top-text {
    font-family: Lato;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    text-align: left;

}
.modal-content-body {
    margin-top: 10px;
    font-family: Lato;
    font-size: 14px;
    font-weight: 200;
    line-height: 24px;
    text-align: justified !important;

}

.form-group {
    label {
        font-family: Lato;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        color: #2B3352;
    }

    input, select {
        height: 48px;
        border-radius: 6px;
        border: 1px solid #E7E7E7;
        opacity: 0px;
        outline: 0;
        box-shadow: none;

        font-family: Lato;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        text-align: left;
        color: #555C74;
    }
    textarea {
        border-radius: 6px;
        border: 1px solid #E7E7E7;
        opacity: 0px;
        outline: 0;
        box-shadow: none;

        font-family: Lato;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        text-align: left;
        color: #555C74;
    }
}

.radio-label {
  position: relative;
  left: -19px;
  color: #57595a;
  font-size: 14px;
  line-height: 1.8;
}

.form-check {
  .form-check-input {
    width: 20px;
    height: 20px;
  }
  label {
    margin-top: 3px;
    margin-left: 10px;
    color: #57595a;
    font-size: 14px;
    // line-height: 1.8;
  }
}

.update-buttons {
    flex-wrap: nowrap;
    button {
        font-family: Lato;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
        min-height: 48px;
        box-shadow: none;
        outline: 0;
    }
    .update {
        background: $primary;
        color: $white;
    }
    .cancel {
        color: $primary;
        border: 1px solid $primary;
    }
}

.features-amenities-holder {
  p {
    padding-left: 20px;
  }
}
.features-btn {
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 17.5px;
  text-align: center;
  color: #555C74;
  border: 1px solid #A1A2A3;
  border-radius: 50px;
  margin: 10px 10px 10px 0;
  box-shadow: none;
  outline: 0;
}

.selectedFeature {
  background-color: #0033ea;
  color: #ffffff;
  border: 1px solid #0033ea;
}

::-ms-input-placeholder { /* Edge 12-18 */
    font-family: Lato;
    font-size: 16px;
    font-weight: 200;
    line-height: 24px;
    text-align: left;
    color: #555C74;
}

::placeholder {
    font-family: Lato;
    font-size: 16px;
    font-weight: 200;
    line-height: 24px;
    text-align: left;
    color: #555C74;
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.submitting-text {
  // color: #48b881 !important;
  // text-align: center;
  // margin-top: 20%;
  // font-size: 14px !important;
  animation: blink 1s infinite; 
}


// Loader starts
.loadingDiv {
    position: absolute;
    z-index: 100;
    top: 14%;
    right: 38%;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid var(--primary-color);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--primary-color) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
// Loader ends

@media screen and (max-width: 599px) {
    .update-image {
        height: 215px;
    }
    // Recheck this again someother time
    .main-img {
        .image-main {
            min-height: 280px;
        }
    }
}

</style>


